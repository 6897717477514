<template>
    <b-modal
      :id="identifier"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="closeModal"
    >
      <div class="modal-header">
        <div class="modal-title">Pagar conta(s)</div>
        <span class="icon-box"
          ><v-close class="icon stroke" @click="closeModal"
        /></span>
      </div>
      <validation-observer ref="BillToPayForm">
        <div class="row md-content">
          <div class="col-4">
            <b-form-group id="field_date_release">
              <label for="field_date_release">Data do pagamento</label>
              <date-picker
                class="full"
                v-model="form.date_release"
                format="DD/MM/YYYY"
                placeholder="DD/MM/AAAA"
                :clearable="false"
                :lang="langDatePicker"
              ></date-picker>
            </b-form-group>
          </div>
  
          <div class="col-8">
            <b-form-group id="field_user">
              <label for="field_user">Lançamento realizado por</label>
              <b-form-input
                autocomplete="off"
                v-model="form.user"
                :disabled="true"
              />
            </b-form-group>
          </div>
        </div>
      </validation-observer>
  
      <b-table-simple class="p-table" striped>
        <thead>
          <tr>
            <td class="td-header">Tipo</td>
            <td class="td-header">Categoria</td>
            <td class="td-header">Pagamento</td>
            <td class="td-header">Conta</td>
            <td class="td-header">Vencimento</td>
            <td class="td-header text-right">Valor</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="installment in installments" :key="installment.id">
            <td class="td-body">
              <div class="change-type">{{ installment.change === 'auto' ? 'Automático' : 'Manual' }}</div>
            </td>
            <td class="td-body">{{ installment?.payment?.category?.name ?? '-' }}</td>
            <td class="td-body">{{ installment?.payment?.payment_method?.name ?? '-' }}</td>
            <td class="td-body">{{ installment?.payment?.bank_account?.name ?? '-' }}</td>
            <td class="td-body">{{ installment?.due_date ? moment(installment.due_date).format('DD/MM/YYYY') : '-' }}</td>
            <td class="td-body text-right">
              {{ parseNumberToMoney(installment.amount) }}
            </td>
          </tr>
        </tbody>
        <b-tfoot>
          <b-tr>
            <b-td class="total">Total</b-td>
            <b-td colspan="5" class="total text-right">
              {{ parseNumberToMoney(total) }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
  
      <div class="md-button">
        <b-button
          class="wh-button"
          variant="primary"
          size="lg"
          @click="startPay"
        >
          Pagar conta
        </b-button>
      </div>
    </b-modal>
  </template>
  
  <script>
  import moment from 'moment'
  import Close from '@/assets/icons/close.svg'
  import { parseNumberToMoney } from '@/utils/moneyHelper'
  
  export default {
    name: 'BillToReceivePayModal',
    props: {
      identifier: { type: String, required: true },
      installments: { type: Array, required: true },
      getBillsToPay: Function,
    },
    components: {
      'v-close': Close,
    },
    watch: {
      installments: function () {
        this.getFinalAmount()
      }
    },
    data() {
      const clinic = JSON.parse(localStorage.getItem('clinic'))
      const user = JSON.parse(localStorage.getItem('user'))
      return {
        clinic_id: clinic.id,
        validated: false,
        total: 0,
        errors: {},
        user : user,
        form: {
          id: null,
          user: user.name,
          date_release: new Date()
        },
        langDatePicker: {
          formatLocale: {
            weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'] // DIAS DA SEMANA NO CAMPO DATA
          }
        }
      }
    },
  
    methods: {
      moment,
      parseNumberToMoney,
      startPay() {
        if (this.installments.every(el => el.change === 'manual' )) {
          this.accountPay()
          return
        }
  
        this.$swal({
          icon: 'question',
          html: `<div class="body-alert">Existem valores configurados como recebimento automático, os mesmos receberão a data de pagamento <span>${this.moment(this.form.date_release).format('DD/MM/YY')}</span>. Deseja continuar?</div>`,
          showCancelButton: true,
          confirmButtonColor: '#F63220',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
         }).then(res => {
          if (res.isConfirmed) {
            this.accountPay()
          }
        })
      },
      async accountPay() {
        const data = this.installments.map(installment => ({
          id: installment.id,
          payment_date: this.form.date_release,
          status: 'paid_out',
        }))
        try {
          await this.api.updateFinancialInstallments(data);
          this.$toast.success('Contas pagas com sucesso');
          this.getBillsToPay();
          this.closeModal();
        } catch(ex) {
          this.$toast.error(ex.message);
        }
      },
      clearForm() {
        this.form = {
          id: null,
          user: this.user.name,
          date_release: new Date()
        }
        this.validated = false
      },
      isValidState(model) {
        return this.errors[model] !== true
      },
      closeModal() {
        this.clearForm()
        this.$bvModal.hide(this.identifier)
        this.$emit('onHide');
      },
      getFinalAmount() {
        this.total = this.installments
          .map(item => item.amount)
          .reduce((prev, curr) => prev + curr, 0)
      }
    }
  }
  </script>
  <style scoped lang="scss">
  .modal-header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-title {
    font-size: 18px;
    color: var(--dark-blue);
    font-weight: 600;
  }
  .icon {
    width: 25px;
  }
  .md-content {
    padding: 30px 20px 10px 20px;
  }
  .md-button {
    text-align: right;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .td-header {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: var(--type-active);
    border: none;
  }
  .td-body {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--type-active);
  
    .change-type {
      display: flex;
      padding: 2px 8px;
      border-radius: 30px;
      color: white;
      font-size: 12px;
      justify-content: center;
      background-color: var(--blue-300);
    }
  }
  .total {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: var(--type-active);
  }
  </style>
  